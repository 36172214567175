<template>
    <div class='list_content'>
        <h2 style="text-align: center;">表单标记</h2>
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="姓名 : ">
                <div style="width:200px;background-color:silver;padding-left:20px;">{{ form.name }}</div>
            </el-form-item>

            <el-form-item label="手机号 : ">
                <div style="width:200px;background-color:silver;padding-left:20px">{{ form.telphone }}</div>
            </el-form-item>

            <!-- <el-form-item label="年龄 : ">
                                <div style="width:200px;background-color:silver;padding-left:20px">{{form.age}}</div>
                            </el-form-item>

                            <el-form-item label="性别 :">
                                <div style="width:200px;background-color:silver;padding-left:20px">{{form.gender}}</div>
                            </el-form-item> -->

            <el-form-item label="状态 : ">
                <el-select v-model="form.form_status" placeholder="请选择">
                    <el-option label="未操作" value=1></el-option>
                    <el-option label="未接听" value=2></el-option>
                    <el-option label="已致电" value=3></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="备注 : ">
                <el-input type="textarea" :rows="5" v-model="form.remark"></el-input>
            </el-form-item>

            <el-form-item v-if="fpShow" label="来源 : ">
                <el-select v-model="form.app_name" placeholder="请选择">
                    <el-option label="字节-抖音" value="字节-抖音"></el-option>
                    <el-option label="字节-今日头条" value="字节-今日头条"></el-option>
                    <el-option label="微信" value="微信"></el-option>
                    <el-option label="快手" value="快手"></el-option>
                    <el-option label="百度" value="百度"></el-option>
                    <el-option label="小红书" value="小红书"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item v-if="fpShow" label="创建时间 : ">
                <el-input v-model="form.create_time"></el-input>
            </el-form-item>

            <el-button type="primary" @click="onSubmit">确认</el-button>
            <el-button @click="goback">取消</el-button>
        </el-form>
    </div>
</template>

<script>
import LeftMenu from '@/components/left.vue'
import HeaderTop from '@/components/top.vue'
import Footer from '@/components/footer.vue'

export default {
    name: 'FormInfoView',
    // 注册组件
    components: {
        LeftMenu,
        HeaderTop,
        Footer
    },
    props: {},
    // data() 为变量赋值等
    data() {
        return {
            form_id: 0,
            cache_user: {},
            form: {},
            fpShow: false, // 是否显示手动分配
            lv3User: [],
        }
    },

    created() {
        // 从参数获取userid
        this.form_id = this.$route.query.form_id
        // 从缓存获取 登录用户
        this.cache_user = JSON.parse(sessionStorage.getItem('user'))
        this.onload()
    },

    methods: {
        onload() {
            let that = this
            // 添加权限
            if (that.cache_user.user_level == 1 || that.cache_user.user_level == 2) {
                that.fpShow = true
            }

            let req = {
                form_id: Number(that.form_id)
            }
            this.myAxios.post(that.myBaseurl + '/form/info', req)
                .then(function (res) {
                    if (res.data.Code === '200') {
                        console.log(res.data.Data);
                        that.form = res.data.Data
                        switch (that.form.form_status) {
                            case 1:
                                that.form.form_status = "未操作"
                                break;
                            case 2:
                                that.form.form_status = "未接听"
                                break;
                            case 3:
                                that.form.form_status = "已致电"
                                break;
                        }
                    } else {
                        alert("获取表单信息失败!")
                    }
                })

            this.myAxios.post(that.myBaseurl + '/user/lv3', req)
                .then(function (res) {
                    if (res.data.Code === '200') {
                        console.log(res.data.Data);
                        that.lv3User = res.data.Data
                    } else {
                        alert("获取lv3User用户信息失败!")
                    }
                })
        },

        onSubmit() {
            console.log('开始修改!');
            let that = this
            that.form.form_id = Number(that.form.id)
            that.form.user_id = Number(that.form.user_id)

            switch (that.form.form_status) {
                case "未操作":
                    that.form.form_status = 1
                    break;
                case "未接听":
                    that.form.form_status = 2
                    break;
                case "已致电":
                    that.form.form_status = 3
                    break;
            }
            that.form.form_status = Number(that.form.form_status)
            this.myAxios.post(that.myBaseurl + '/form/bj', that.form)
                .then(function (res) {
                    if (res.data.Code === '200') {
                        // alert("修改成功 !")
                        that.$router.push({
                            path: '/form/list',
                        })
                    } else {
                        alert(res.data.Msg)
                        return
                    }
                })
        },
        goback() {
            history.go(-1)
        }
    }
}

</script>

<style scoped="scoped">
/*大框体样式*/
.el-main {
    height: calc(100vh - 120px);
}

.list_content {
    max-width: 800px;
}

.el-form-item__label {
    max-width: 200px !important;
}
</style>
